import dayjs from 'dayjs';
import jszip from 'jszip';

import { toast } from 'react-toastify';

import { Device } from '../models/Device';
import { Document } from '../models/Document';
import { Department, Order, OrderType } from '../models/Order';
import { User } from '../models/User';
import { documentURL } from './api-client';

export interface Option {
  label: string;
  value: string;
}

export const createOption = (label: string): Option => ({
  label,
  value: label,
});

export const createOptions = (label: string, value: string): Option => ({
  label,
  value,
});

export const getOptionValue = (options: Option[]) => options.map((opt) => opt.value);

export const agreementOption = [
  {
    label: 'Áno',
    value: 'true',
  },
  {
    label: 'Nie',
    value: 'false',
  },
];

export const updateObj = <T, K extends keyof T>(obj: T, key: K, value: T[K]) => {
  return {
    ...obj,
    [key]: value,
  };
};

export enum AllFilter {
  ALL = 'Všetky',
}

interface Entity {
  [key: string]: any;
}

interface Item {
  id: string;
  [key: string]: any;
}

export const deleteItem = <T extends Entity, K extends keyof T>(obj: T, key: K, id: string) => {
  if (Array.isArray(obj[key])) {
    const updatedItems = obj[key].filter((item: Item) => item.id !== id);

    return {
      ...obj,
      [key]: updatedItems,
    };
  }

  return obj;
};

export const displayCheckingDate = (checkingDate: string | undefined) => {
  if (!checkingDate) return '';

  const checkingDateAsDate = new Date(checkingDate);

  return `${dayjs(checkingDateAsDate).format('D.M.YYYY')} ${
    dayjs(new Date()).isBefore(dayjs(checkingDateAsDate))
      ? `(o ${dayjs(checkingDateAsDate).diff(dayjs(new Date()), 'days')} dní)`
      : ''
  }`;
};

export const displayDeviceInDropdown = (device: Device) => {
  const props = device.props;

  const manufacturer = props?.manufacturer || '';
  const label = device.label;
  const serialNumber = props?.serialNumber || '';
  const checkingDate = displayCheckingDate(device.checkingDate);

  const values = [manufacturer, label, serialNumber, checkingDate].filter(
    (value) => value.trim() !== ''
  );

  return values.join(' | ');
};

export const parseDepartmentFromPermission = (permission: string) => {
  const [, , departmentCode] = permission.split('.');
  return Object.values(Department).includes(departmentCode as Department) ? departmentCode : null;
};

export const hasOrderPermission = (
  user: User | null,
  permissionType: string,
  department?: Department
) => {
  return (
    user?.permissions?.some((permission) =>
      permission.startsWith(`order.${permissionType}${department ? `.${department}` : ''}`)
    ) ?? false
  );
};

export const isMontageDepartment = (department: Department) => {
  return [Department.bm, Department.zm].includes(department);
};

export const isServiceDepartment = (department: Department) => {
  return [Department.bs, Department.zs].includes(department);
};

export const displayOrderNumber = (order: Order) => {
  const paddedIntId = order.intId.toString().padStart(5, '0');

  if (isMontageDepartment(order.department)) {
    const initials =
      order.seller?.name
        .split(' ')
        .map((word) => word[0].toUpperCase())
        .join('') ?? '';
    const year = order.date.slice(2, 4);

    return `${initials}${year}-${paddedIntId}`;
  } else {
    return `${OrderType[order.type][0]}${paddedIntId}`;
  }
};

export const zipFile = async (file: File) => {
  const zip = new jszip();
  zip.file(file.name, await file.arrayBuffer());

  return await zip.generateAsync({
    type: 'blob',
    compression: 'DEFLATE',
    compressionOptions: {
      level: 9,
    },
  });
};

export const handleDocumentInteraction = async (
  doc: Document,
  event: React.MouseEvent<HTMLAnchorElement, MouseEvent>
) => {
  const fileExtension = doc.name?.split('.').pop()?.toLowerCase() ?? '';
  const needsSpecialDownloadHandling = ['skp', 'dwg'].includes(fileExtension);

  if (needsSpecialDownloadHandling) {
    event.preventDefault();

    try {
      const response = await fetch(`${documentURL}${doc.id}`, {
        method: 'GET',
        credentials: 'include',
      });

      if (!response.ok) {
        throw new Error('Could not download document.');
      }

      const blob = await response.blob();
      const url = window.URL.createObjectURL(blob);
      const link = document.createElement('a');

      link.href = url;
      link.setAttribute('download', doc?.name ?? '');
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      window.URL.revokeObjectURL(url);
    } catch (error) {
      console.error('Download error:', error);
      toast.error('Nepodarilo sa stiahnuť dokument.');
    }
  }
};

export const decimalRegex = /^\d+([.,]\d{1,2})?$/;

// TODO: move text utils to ./text.ts
