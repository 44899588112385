import { toast } from 'react-toastify';
import { combineReducers } from 'redux';
import { call, put, select, takeLatest } from 'redux-saga/effects';

import { ExtendedAxiosResponse } from '../../helpers/api-client';
import {
  AppAction,
  createActionType,
  createLoadingStateReducer,
  createReducer,
  LoadingStatus,
  RequestActionTypes,
} from '../../helpers/redux/redux-helpers';
import { buildRoute } from '../../helpers/route/route-builder';
import { AppRoutes } from '../../helpers/route/routes/app-routes';
import { DepartureRoutes } from '../../helpers/route/routes/departure-routes';
import { history } from '../../helpers/store/root-reducer';
import {
  CreateDeparture,
  Departure,
  DepartureFilter,
  DeparturePlace,
  DepartureResponse,
  UpdateDeparture,
  ViewMode,
} from '../../models/Departure';
import { OrderPlace } from '../../models/Order';
import { ordersGetOrderActions } from '../orders/actions';
import {
  DeparturesActionTypes,
  departuresCreateDepartureActions,
  departuresDeleteDepartureActions,
  departuresGetDepartureActions,
  departuresGetDeparturesActions,
  departuresUpdateDepartureActions,
  DeleteDepartureActions,
  GetDeparturesActions,
  UpdateAllDepartureActions,
  departuresDeletePlaceActions,
  departuresCreatePlaceActions,
} from './actions';
import { api } from './api';
import { selectDepartureFilter, selectDeparturesViewMode } from './selectors';

const ITEMS_PER_PAGE = 50;

/* STATE */
export interface DeparturesState {
  departureResponse: DepartureResponse;
  selectedDeparture: Departure | null;
  loading: LoadingStatus;
  departureFilter: DepartureFilter;
  viewMode: ViewMode;
}

/* REDUCERS */
const initialState: DeparturesState = {
  departureResponse: {
    limit: 0,
    total: 0,
    skip: 0,
    data: [],
  },
  departureFilter: {
    departments: [],
  },
  selectedDeparture: null,
  loading: LoadingStatus.initial,
  viewMode: 'TABLE',
};

const departureResponse = createReducer(initialState.departureResponse, {
  [DeparturesActionTypes.GetDepartures]: {
    [RequestActionTypes.REQUEST]: initialState.departureResponse,
    [RequestActionTypes.SUCCESS]: (state: DepartureResponse, payload: DepartureResponse) => payload,
    [RequestActionTypes.FAILURE]: () => initialState.departureResponse,
  },
});

const selectedDeparture = createReducer(initialState.selectedDeparture, {
  [DeparturesActionTypes.GetDeparture]: {
    [RequestActionTypes.SUCCESS]: (state: Departure | null, payload: Departure) => payload,
    [RequestActionTypes.FAILURE]: () => initialState.selectedDeparture,
  },
  [DeparturesActionTypes.CreateDeparture]: {
    [RequestActionTypes.SUCCESS]: (state: Departure | null, payload: Departure) => payload,
    [RequestActionTypes.FAILURE]: () => initialState.selectedDeparture,
  },
  [DeparturesActionTypes.CleanDeparture]: () => initialState.selectedDeparture,
  [DeparturesActionTypes.CleanSelectedDeparture]: () => initialState.selectedDeparture,
});

const departureFilter = createReducer(initialState.departureFilter, {
  [DeparturesActionTypes.SetFilter]: (state: DepartureFilter, payload: DepartureFilter) => payload,
  [DeparturesActionTypes.CleanFilter]: () => initialState.departureFilter,
  [DeparturesActionTypes.CleanDepartures]: () => initialState.departureFilter,
});

const loading = createLoadingStateReducer(initialState.loading, {
  [DeparturesActionTypes.GetDepartures]: [
    RequestActionTypes.REQUEST,
    RequestActionTypes.SUCCESS,
    RequestActionTypes.FAILURE,
  ],
  [DeparturesActionTypes.GetDeparture]: [
    RequestActionTypes.REQUEST,
    RequestActionTypes.SUCCESS,
    RequestActionTypes.FAILURE,
  ],
  [DeparturesActionTypes.CreateDeparture]: [
    RequestActionTypes.REQUEST,
    RequestActionTypes.SUCCESS,
    RequestActionTypes.FAILURE,
  ],
  [DeparturesActionTypes.UpdateDeparture]: [
    RequestActionTypes.REQUEST,
    RequestActionTypes.SUCCESS,
    RequestActionTypes.FAILURE,
  ],
  [DeparturesActionTypes.DeleteDeparture]: [
    RequestActionTypes.REQUEST,
    RequestActionTypes.SUCCESS,
    RequestActionTypes.FAILURE,
  ],
});

const viewMode = createReducer(initialState.viewMode, {
  [DeparturesActionTypes.SetViewMode]: (state: ViewMode, payload: ViewMode) => payload,
});

export default combineReducers<DeparturesState>({
  departureResponse,
  departureFilter,
  selectedDeparture,
  loading,
  viewMode,
});

/* SAGAS */
function* getDepartures({ payload }: AppAction<GetDeparturesActions>) {
  const selectedFilter: DepartureFilter = yield select(selectDepartureFilter);
  const departments = payload.filter?.departments;
  const viewMode: ViewMode = yield select(selectDeparturesViewMode);

  let allData: Departure[] = [];
  const limit = payload?.limit || ITEMS_PER_PAGE;
  let skip = payload?.skip || 0;
  let totalFetched = 0;
  let totalItems = 0;

  do {
    const resp: ExtendedAxiosResponse = yield call(api.getAll, limit, skip, {
      ...selectedFilter,
      ...(departments && departments.length && { departments: departments }),
    });

    if (resp.ok) {
      resp.data?.data.forEach((departure: any) => {
        departure.id = departure.departure_id ? departure.departure_id : departure.id;
      });

      allData = [...allData, ...resp.data.data];
      totalFetched += resp.data.limit;
      totalItems = resp.data.total;
      skip += resp.data.limit;
    } else {
      yield put(departuresGetDeparturesActions.failure());
      return;
    }
  } while (viewMode === 'CALENDAR' && totalFetched < totalItems);

  yield put(
    departuresGetDeparturesActions.success({
      data: allData,
      limit: viewMode === 'CALENDAR' ? null : limit,
      skip: viewMode === 'CALENDAR' ? 0 : skip,
      total: totalItems,
    })
  );
}

function* getDeparture({ payload }: AppAction<string>) {
  const resp: ExtendedAxiosResponse = yield call(api.get, payload);
  if (resp.ok) {
    const res: ExtendedAxiosResponse = yield call(api.getPlaces, payload);
    if (res.ok) {
      const places = res.data.data
        .map((entry: { place: DeparturePlace }) => entry.place)
        .filter((place: DeparturePlace) => place);

      yield put(
        departuresGetDepartureActions.success({
          ...resp.data,
          places: places,
        })
      );
    }
  } else {
    yield put(departuresGetDepartureActions.failure());
  }
}

function* createDeparture({ payload }: AppAction<CreateDeparture>) {
  const resp: ExtendedAxiosResponse = yield call(api.create, payload);
  if (resp.ok) {
    yield put(departuresCreateDepartureActions.success(resp.data));
    history.push(
      buildRoute([AppRoutes.Departures, DepartureRoutes.DepartureDetails], {
        departureId: resp.data.id,
      })
    );
  } else {
    yield put(departuresCreateDepartureActions.failure());
  }
}

function* deleteDeparture({ payload }: AppAction<DeleteDepartureActions>) {
  const resp: ExtendedAxiosResponse = yield call(api.delete, payload.id);
  if (resp.ok) {
    if (payload.orderId) {
      const res: ExtendedAxiosResponse = yield call(api.getOrder, payload.orderId);
      if (res.ok) {
        const re: ExtendedAxiosResponse = yield call(api.getOrderPlaces, payload.orderId);
        if (re.ok) {
          const places = re.data.data
            .map((entry: { place: OrderPlace }) => entry.place)
            .filter((place: OrderPlace) => place);

          yield put(
            ordersGetOrderActions.success({
              ...res.data,
              places: places,
            })
          );
        }
      }
    } else {
      const selectFilter: DepartureFilter = yield select(selectDepartureFilter);
      const res: ExtendedAxiosResponse = yield call(api.getAll, ITEMS_PER_PAGE, 0, selectFilter);
      if (res.ok) {
        yield put(departuresGetDeparturesActions.success(res.data));
      }
    }
  } else {
    yield put(departuresDeleteDepartureActions.failure());
  }
}

function* updateDeparture({ payload }: AppAction<UpdateDeparture>) {
  const resp: ExtendedAxiosResponse = yield call(api.update, payload);
  if (resp.ok) {
    yield put(departuresUpdateDepartureActions.success(resp.data));
    yield put(departuresGetDepartureActions.request(payload.id));
  } else {
    yield put(departuresUpdateDepartureActions.failure());
  }
}

function* setFilter({ payload }: AppAction<DepartureFilter>) {
  const viewMode: ViewMode = yield select(selectDeparturesViewMode);

  let allData: Departure[] = [];
  const limit = ITEMS_PER_PAGE;
  let skip = 0;
  let totalFetched = 0;
  let totalItems = 0;

  do {
    const resp: ExtendedAxiosResponse = yield call(api.getAll, ITEMS_PER_PAGE, skip, payload);

    if (resp.ok) {
      resp.data?.data.forEach((departure: any) => {
        departure.id = departure.departure_id ? departure.departure_id : departure.id;
      });

      allData = [...allData, ...resp.data.data];
      totalFetched += resp.data.limit;
      totalItems = resp.data.total;
      skip += resp.data.limit;
    } else {
      yield put(departuresGetDeparturesActions.failure());
      return;
    }
  } while (viewMode === 'CALENDAR' && totalFetched < totalItems);

  yield put(
    departuresGetDeparturesActions.success({
      data: allData,
      limit: viewMode === 'CALENDAR' ? null : limit,
      skip: viewMode === 'CALENDAR' ? 0 : skip,
      total: totalItems,
    })
  );
}

function* createPlace({ payload }: AppAction<UpdateAllDepartureActions>) {
  const createPlaceRes: ExtendedAxiosResponse = yield call(api.createPlace, payload.id, {
    placeId: payload.value,
  });
  if (createPlaceRes.ok) {
    yield put(departuresCreatePlaceActions.success(createPlaceRes.data));
    const getPlacesRes: ExtendedAxiosResponse = yield call(
      api.getPlaces,
      createPlaceRes.data.departureId
    );

    if (getPlacesRes.ok) {
      const getDepartureRes: ExtendedAxiosResponse = yield call(
        api.get,
        createPlaceRes.data.departureId
      );

      if (getDepartureRes.ok) {
        const places = getPlacesRes.data.data
          .map((entry: { place: DeparturePlace }) => entry.place)
          .filter((place: DeparturePlace) => place);

        yield put(
          departuresGetDepartureActions.success({
            ...getDepartureRes.data,
            places: places,
          })
        );
        toast.success('Uložené');
        return;
      }
    }
  }

  toast.error('Vyskytla sa chyba');
}

function* createDevice({ payload }: AppAction<UpdateAllDepartureActions>) {
  const resp: ExtendedAxiosResponse = yield call(api.createDevice, payload.id, {
    deviceId: payload.value,
  });
  if (resp.ok) {
    toast.success('Uložené');
  } else {
    toast.error('Vyskytla sa chyba');
  }
}

function* createUser({ payload }: AppAction<UpdateAllDepartureActions>) {
  const resp: ExtendedAxiosResponse = yield call(api.createUser, payload.id, {
    userId: payload.value,
  });
  if (resp.ok) {
    toast.success('Uložené');
  } else {
    toast.error('Vyskytla sa chyba');
  }
}

function* deletePlace({ payload }: AppAction<UpdateAllDepartureActions>) {
  const deletePlaceRes: ExtendedAxiosResponse = yield call(
    api.deletePlace,
    payload.id,
    payload.value
  );
  if (deletePlaceRes.ok) {
    yield put(departuresDeletePlaceActions.success(deletePlaceRes.data));
    const getPlacesRes: ExtendedAxiosResponse = yield call(
      api.getPlaces,
      deletePlaceRes.data.departureId
    );
    if (getPlacesRes.ok) {
      const getDepartureRes: ExtendedAxiosResponse = yield call(
        api.get,
        deletePlaceRes.data.departureId
      );
      if (getDepartureRes.ok) {
        const places = getPlacesRes.data.data
          .map((entry: { place: DeparturePlace }) => entry.place)
          .filter((place: DeparturePlace) => place);

        yield put(
          departuresGetDepartureActions.success({
            ...getDepartureRes.data,
            places: places,
          })
        );
        toast.success('Uložené');
        return;
      }
    }
  }

  toast.error('Vyskytla sa chyba');
}

function* deleteDevice({ payload }: AppAction<UpdateAllDepartureActions>) {
  const resp: ExtendedAxiosResponse = yield call(api.deleteDevice, payload.id, payload.value);
  if (resp.ok) {
    toast.success('Uložené');
  } else {
    toast.error('Vyskytla sa chyba');
  }
}

function* deleteUser({ payload }: AppAction<UpdateAllDepartureActions>) {
  const resp: ExtendedAxiosResponse = yield call(api.deleteUser, payload.id, payload.value);
  if (resp.ok) {
    toast.success('Uložené');
  } else {
    toast.error('Vyskytla sa chyba');
  }
}

/* EXPORT */
export function* departuresSaga() {
  yield takeLatest(
    createActionType(DeparturesActionTypes.GetDepartures, RequestActionTypes.REQUEST),
    getDepartures
  );
  yield takeLatest(
    createActionType(DeparturesActionTypes.GetDeparture, RequestActionTypes.REQUEST),
    getDeparture
  );
  yield takeLatest(
    createActionType(DeparturesActionTypes.CreateDeparture, RequestActionTypes.REQUEST),
    createDeparture
  );
  yield takeLatest(
    createActionType(DeparturesActionTypes.DeleteDeparture, RequestActionTypes.REQUEST),
    deleteDeparture
  );
  yield takeLatest(
    createActionType(DeparturesActionTypes.UpdateDeparture, RequestActionTypes.REQUEST),
    updateDeparture
  );
  yield takeLatest(
    createActionType(DeparturesActionTypes.CreatePlace, RequestActionTypes.REQUEST),
    createPlace
  );
  yield takeLatest(
    createActionType(DeparturesActionTypes.CreateDevice, RequestActionTypes.REQUEST),
    createDevice
  );
  yield takeLatest(
    createActionType(DeparturesActionTypes.CreateUser, RequestActionTypes.REQUEST),
    createUser
  );
  yield takeLatest(
    createActionType(DeparturesActionTypes.DeletePlace, RequestActionTypes.REQUEST),
    deletePlace
  );
  yield takeLatest(
    createActionType(DeparturesActionTypes.DeleteDevice, RequestActionTypes.REQUEST),
    deleteDevice
  );
  yield takeLatest(
    createActionType(DeparturesActionTypes.DeleteUser, RequestActionTypes.REQUEST),
    deleteUser
  );
  yield takeLatest(DeparturesActionTypes.SetFilter, setFilter);
}
